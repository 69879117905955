import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import "@uiw/react-textarea-code-editor/dist.css"

function MyApp({ Component, pageProps }: AppProps) {
  return <div className="h-full">
    <Component {...pageProps} />
    <ToastContainer
      position="top-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </div>
}

export default MyApp
